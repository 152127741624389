export default function sendToApproveOpportunity(data){
  this.load();
  this.rest.post(
    "getSendToApproveOpportunityTemplate",
    { module: data.module, id: data.id },
    email => {
      this.unLoad();

      const emailData = {
        parent_type: data.module,
        parent_id: data.id,
        parent_name: data.name,

        to_addrs: email.to_addrs || "",
        cc_addrs: email.cc_addrs || "",
        ccExpanded: true,

        recordModule: data.module,
        recordId: data.id,
        keepSelf: true,

        description_html: email.text,
        name: email.subject
      };

      this.openEmailPopup(emailData);
    }
  );


}