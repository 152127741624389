import React, { Component } from "react";
import PureComponent from "../../pure";

import  sAction  from "sAction";

class ReportWindowColumn extends PureComponent {

    render() {
    const data = this.props.data
    const index = this.props.index
    const module = this.props.module
    const vname = this.props.vname
    const setWidth = this.props.setWidth;
    let show = false

    if(data.def.get("show") === true || data.def.get("show") === undefined){
        show = true
    }

    var style = {};
    if(setWidth == true){
      style["width"] = data.def.get("width");
    }

    return (
        <div style={style} key={index+data.name}
         className={show  ? "reportWindowColumn" : "reportWindowColumn hidden"}
         data-name={data.name}
         data-way={index}>
        <span className="reportWindowColumnSpan">{vname}</span>
        <span className="reportWindwoColumnResizeHolder" data-resizetype="width">|</span>
      </div>
    );
  }
}
export default ReportWindowColumn;
