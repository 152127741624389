import React from "react";
import PureComponent from "../pure";

import  sAction  from "sAction";

export default class ListViewPagination extends PureComponent {
  pagination(type) {
    const data = {
      offset: this.props.offset,
      limit: this.props.limit,
      rowCount: this.props.rowCount,
      rowTotalCount: this.props.rowTotalCount,
      page: this.props.page,
      prefix: this.props.prefix,
      type: type
    };
    sAction.listPagination(data);
  }
  render() {
    const module = this.props.module;
    const offset = this.props.offset;
    const rowCount = this.props.rowCount;
    var selected = this.props.selected;
    const selectedActive = this.props.selectedActive;
    const page = this.props.page;
    const filter = this.props.filter;
    const rowTotalCount = this.props.rowTotalCount

    var pagination = (
      <div className="pagination">
        <div
          className="arrow icon-pageStart"
          onClick={() => this.pagination("start")}
        />
        <div
          className="arrow icon-pageBack"
          onClick={() => this.pagination("back")}
        />
        <div className="pageCount">
          {/*{sAction.translate("LBL_ACTPAGE") + " " + page} */}
          {page}
        </div>
        {this.props.limit === this.props.rowCount ? (<div
          className="arrow icon-pageNext"
          onClick={() => this.pagination("next")}
        />) : "\u00A0 | "}
        {this.props.limit === this.props.rowCount && rowTotalCount != null &&
          <div
          className="arrow icon-pageEnd"
          onClick={() => this.pagination("end")}
        />
        }
      </div>
    );

    const type = this.props.type;
    var pomClass = "";
    if (type == "rightPanelQuotes") {
      pomClass = " rightPanelQuotes";
    }
    const numbers = offset + 1 <= rowCount + offset ? (offset + 1) + "-" + (rowCount + offset) : "";
    return (
        <div className={"paginationContainer footerBlock" + pomClass}>
          {pagination}
          {numbers}
          {rowTotalCount == null ?
            <div className="icon-listviewCount listviewCount" onClick={() => sAction.listViewCount(this.props.prefix)} 
            title={sAction.translate("LBL_ALL_RECORDS")}
          />
          :
           <div title={sAction.translate("LBL_ALL_RECORDS")}>{"\u00A0("+sAction.formatNumber(rowTotalCount, 0)+")\u00A0"}</div>
          }  
        </div>
    );
  }
}
