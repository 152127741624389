export default function requiredFieldsCheckMsg(prefix) {
  if (!prefix) {
    console.error("Neni vyplnen prefix");
  }
  if (!this.requiredFieldsCheck(prefix)) {
    const save_record = document.querySelectorAll("#save_record");
    if (save_record[save_record.length - 1]) {
      save_record[save_record.length - 1].click();
    } else {
      this.error("Nejprve prosím vyplňte všechny povinné položky.");
    }
    return false;
  }

  return true;
}