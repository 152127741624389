import PureComponent from "../../pure";
import  sAction  from "sAction";

export default class FilterBasic extends PureComponent {
    componentDidUpdate() {
    const actFilter = this.props.actFilter;
    if (actFilter == null || actFilter.actCustom == false) {
      this.props.parent.normal();
    } else {
      this.props.parent.advanced();
    }
  }
  order(field, asc) {
    const prefix = this.props.prefix;
    sAction.orderBy(field, asc, prefix);
  }
  addFilter(field, type, value) {
    const data = this.props.data;
    const prefix = this.props.prefix;
    var filter = {
      field: field,
      type: type,
      value: value
    };
    var filters = new Array(filter);
    if (data.type == "enum" && value == "") {
      /* pokud je typ pole enum tak prázdná hodnota ze selectu zruší filter */
      sAction.removeFilter(field, filters, "and", prefix);
    } else {
      const data = {
        field: field,
        filters: filters,
        operator: "and",
        reload: true,
        prefix: prefix
      };
      sAction.addFilter(data);
    }
  }
  removeFilter(field) {
    const prefix = this.props.prefix;
    var filter = {
      field: field,
      type: null,
      value: null
    };
    var filters = new Array(filter);
    const data = {
      field: field,
      filters: filters,
      operator: "and",
      prefix: prefix
    };
    sAction.dsClear()
    sAction.dsAdd("set", "view/removeFieldText", true)
    sAction.dsProcess()
    sAction.removeFilter(data);
  }
  addFieldFunction(e){
    const func = e.target.value;
    const module = this.props.data.get("fieldModule");
    const field = this.props.data.get("name");
    const prefix = this.props.prefix;
    sAction.addFieldFunction(module+"."+field,func,prefix);
  }
  customInputKeyUp(e){
    if(e.keyCode == 13){
      const data = this.props.data;
      this.addCustomFilter(data.get("name"));
    }
  }
}
