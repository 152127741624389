export default function eMistr(data) {
  var input=document.createElement('input');
  input.type="file";
  input.style="display:none";
  // input.multiple = true;
  input.onchange = (e) => {

    const sendData = {
      data: {
        module: data.module,
        id: data.id,
        files: e.target.files,
      },
      action: "importFile",
      responseType: "json",
      success: response => {
        this.unLoad();
        if (response.status === "error") {
          this.error(response.message+"<br><br>Zpracováno řádků: "+response.imported_count);
        } else {
          const data = {
            // Potvrzení
            header: this.translate("LBL_CONFIRM"),
            // color:"red",
            buttons: [
              // Zrušit
              //{label: this.translate("LBL_CANCEL", "Home"), callback: () => this.popupHide()},

              // Pokračovat
              {label: this.translate("LBL_CONTINUE", "Home"), callback: () => {this.popupHide();}},
            ],
          };

          this.routeDetailView(data);

          this.popup("Hotovo. Zpracováno " +response.imported_count+ " řádků.", data);
        }
      },
      progress: progress => {
      }
    }
    this.load();
    this.filesXhr(sendData);
  }

  setTimeout(function(){
    input.click();
  },200);
}