export default function approveOpportunity(params) {
  this.saveField(
    {
      way: params.prefix + "/fields/sales_stage",
      name: "sales_stage",
      value: "Qualification"
    },
    true
  );

  const save_record = document.querySelectorAll("#save_record");
  save_record[save_record.length-1].click();

}