export default function detailContent(data, content) {
  let panels = data.view.panels;
  if (Array.isArray(panels)) {
    //SS detail vyjimka a mozna i dalsich modulu - neprijde objekt s klicem panelu, ale rovnou jeho obsah - pole
    panels = {
      "default": panels
    }
  }
  var tabs = new Array();
  var fields = {};
  var recordName = null;

  var tab = {};
  var rowIndex = 0;
  for (var key in panels) {
    if (panels.hasOwnProperty(key)) {
      var value = panels[key];

      var tabDef = {newTab:false}
      if(data.view.templateMeta.tabDefs != undefined){
      tabDef = data.view.templateMeta.tabDefs[key.toUpperCase()];
      }
      if (tabDef.newTab == true || tabs.length == 0) {
        tab = {};
        tab.name = key;
        tab.rows = new Array();
        rowIndex = 0;
        tabs.push(tab);
      } else {
        tab.rows[rowIndex] = { type: "headerPanel", name: key };
        rowIndex += 1;
      }

      value.forEach(el => {

        if (Array.isArray(el)) {
          el.forEach((field, index) => {
            var name = null;
            if (typeof field == "object") {
              name = field.name;
            } else {
              name = field;
            }
            if (name === '') {
              return;
            }

            if(tab.rows[rowIndex] == undefined) {
              tab.rows[rowIndex] = new Array();
            }
            // _________________________________doplnění definice fieldu
            var def = data.def[name];
            if (def == undefined) {
              if (name.endsWith("_name")) {
                const editName = name.substr(0, name.lastIndexOf("_name"));
                def = data.def[editName];
              }
            }
            if (name == "duration_hours" && def.type == "int") {
              def.type = "hoursMinutes";
              def.minutesName = "duration_minutes";
              def.minutesValue = data.record["duration_minutes"];
            }
            if (name == "name") {
              recordName = data.record[name];
            } else if (name == "last_name") {
              recordName =
                data.record["last_name"] + " " + data.record["first_name"];
            } else if (name == "document_name") {
              recordName = data.record[name];
            } else if (data.record.name) {
              recordName = data.record.name;
            }

            // else if (name == "phone_work") {
            //
            // }
            else if(name == "currency_id"){
              def.type = "currency_id";
            }
            if (def != undefined) {
              if (def.type == "relate") {
                def["id_value"] = data.record[def.id_name];
              }
              if (def.type == "parent") {
                def["parent_type_value"] = data.record["parent_type"];
                def["id_value"] = data.record["parent_id"];
              }
              if (def.type == "file") {
                var [fileId, fileName] = (data.record[name]||"").split("::");
                if (!fileName) {
                  fileName = fileId;
                  fileId = data.record["id"];
                }
                def.file = {
                  id: fileId,
                  source: "sugar",
                  name: fileName,
                };
              }
              if(def.group != undefined && def.name.indexOf("street") != -1){
                data.def.forEachObject((fieldDef,key) => {
                  if(key != def.name && fieldDef.name.indexOf(def.group) != -1){
                    const value = data.record[key];
                    fields[key] = {def:fieldDef,value:value};
                  }
                })
                def.type = "address";
              }
            }
            // _________________________________doplnění definice fieldu END

            const fieldToStore = {
              name: name,
              value: data.record[name],
              def: def
            };

            fields[name] = {def:def,value:data.record[name]};
            tab.rows[rowIndex].push(fieldToStore);
          });
        } else {
          for (var index in el) {
            if (el.hasOwnProperty(index)) {
              const field = el[index];

              if (tab.rows[rowIndex] == undefined) {
                tab.rows[rowIndex] = new Array();
              }

              const fieldToStore = {
                name: field.name,
                value: data.record[field.name],
                def: data.def[field.name]
              };
              fields[field.name] = {def:data.def[field.name],value:data.record[field.name]};
              tab.rows[rowIndex].push(fieldToStore);
            }
          }
        }
        rowIndex += 1;
      });
    }
  }
  // _______ pokud není vytáhly assigned_user
    if(fields.assigned_user_name == undefined){

      var def =data.def["assigned_user_name"];
      if(def == undefined){
        def = {};
      }
      def["id_name"] = "assigned_user_id";
      def["id_value"] = data.record["assigned_user_id"]

      fields.assigned_user_name = {
        value : data.record["assigned_user_name"],
        def
      }
    
    }
  // _______ pokud není vytáhly assigned_user END


  const admin = this.userPreferences.is_admin;
  data.menu.forEach((button,index) => {
    if(button.admin == true && admin != 1){
      delete data.menu[index];
    }
  })

  var modulAcl = this.getAccess(data.module);
  if(modulAcl != undefined){
    if(data.acl != undefined){
      data.acl.forEachObject((a,k) => {
        modulAcl[k] = a
      })
    }
  }
  content.acl = modulAcl;



  content.recordName = this.decodeHTMLEntities(recordName);
  content.tabs = tabs;
  content.customJS = data.js;
  content.actionButtons = data.menu;
  content.timeline = data.timeline
  content.fields = fields
  content.dashboard = data.dashboard
  content.activities = data.activities
  content.readonly = data.readonly;
  if (data.module == "Meetings" || data.module == "Calls") {
    content.invitees = data.invitees;
  }
    //_________ SUBPANELS
    content.subpanels = data.subpanels;
    // _________SUBPANELS END
    content.customData = data.customData;    
}
