
import detailDefault from "../../detailDefault";
import detailProducts from "../Products/detailProducts";

export default class detailProductTemplates extends detailDefault{
  load(sAction, data) {

  }

  update(sAction,data) {
    let products = new detailProducts;
    products.update(sAction, data);
  }

}
