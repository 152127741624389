import React, { Component } from 'react'
import PureComponent from '../../pure'
import TextField from "@material-ui/core/TextField";

import  sAction  from "sAction";
import confProd from "./productLineConfig";
import Button from "../../formElements/Button";

class QTotal extends PureComponent{
    
    valChange(way, ev){
        let newVal = sAction.parseNumber(ev.target.value);
        sAction.updateDetailCustom("field", {way:way, value: newVal});
        //sAction.dataSet(way, newVal);
    }

    render(){
    var data = this.props.data;
    let total_list_price = 0;
    let total_discount_amount = 0;
    const way = this.props.way;
    const keyPre = Date.now();
    const readonly = this.props.readonly;

    const sums = sAction.calcSums(way, {type : "total", data: data});
    const conf = confProd.sums;

    var renderComp = [];
    var firstFiller = null;

    var readonlyClass = "";
    
    if(!readonly){
        renderComp.push(
            <Button 
                className="acmAddGroupBtn"
                key={"addBtnGr"}
                variant="text" 
                onClick={() => sAction.updateDetailCustom("addProdGroup", {way:way+"/groups"})}>
                <div className="buttonIcon icon-addGroupIcon" />
                {sAction.translate("LBL_ADD_GROUP", "Quotes")}
            </Button>
        );
        firstFiller = <div key="firstFiller" style={{width:"14px"}} />
    }
    else{
        readonlyClass = " readOnly";
        firstFiller = <div key="firstFiller" style={{width:"14px"}} />
    }
    
    renderComp.push(
        <div key="secondFiller" style={{width:"45%"}} />
    );

    var self = this;


    /*
    conf.forEach( function(field){ 
        if(!field.visible){
            return;
        }
        if(field.type == "price"){
            renderComp.push(
                <div style={{width:field.width}} className="amountsContainer" key={"sum_"+field.name}>
                    <div>{sAction.translate(field.lbl, field.lblMod)}</div>
                    <div>{sAction.formatNumber(sums[field.name])}</div>
                </div>
            );
        }
        else if(field.type == "input"){
            renderComp.push(
                <div style={{width:field.width}} className="amountsContainer" key={"sum_"+field.name}>
                    <div>{sAction.translate(field.lbl, field.lblMod)}</div>
                    <TextField
                    className="priceInput"
                    margin="normal"
                    variant="outlined"
                    id={field.name}
                    type="text"
                    key={keyPre+"_"+field.name}
                    defaultValue={sAction.formatNumber(data[field.name])}
                    onBlur={(ev) => self.valChange(way+"/"+field.name, ev)}
                    />
                </div>
            );

        }
        else if(field.type == "addBtn"){

        }   

    });*/
        /*
        {
            name: "shipping",
            type: "input",
            lbl: "LBL_SHIPPING",
            lblMod: "Quotes",
            visible: true,
            width: "15%"
        },
        {renderComp}
        */
    return <div className="QTotalContainer">
        <div className="QTotalContainerTopBorder" />
        <div className="QTotalContainerFirstRow QTotalContainerRow">
            {!readonly &&
                <Button 
                    className="acmAddGroupBtn"
                    key={"addBtnGr"}
                    variant="text" 
                    onClick={() => sAction.updateDetailCustom("addProdGroup", {way:way+"/groups"})}>
                    <div className="buttonIcon icon-addGroupIcon" />
                    {sAction.translate("LBL_ADD_GROUP", "Quotes")}
                </Button>
            }
            {firstFiller}
            <div key="secondFiller" style={{width:"45%"}} />
            <div style={{width: "9%"}} className="amountsContainer" key={"sum_total_cost_price"}>
                <div>{sAction.translate("LBL_TOTAL_COST_PRICE", "Quotes")}</div>
                <div>{sAction.formatNumber(sums["total_cost_price"])}</div>
            </div>
            <div style={{width: "9%"}} className="amountsContainer" key={"sum_total_list_price"}>
                <div>{sAction.translate("LBL_SUBTOTAL", "Quotes")}</div>
                <div>{sAction.formatNumber(sums["total_list_price"])}</div>
            </div>
            <div style={{width: "9%"}} className="amountsContainer" key={"sum_total_discount_amount"}>
                <div>{sAction.translate("LBL_LIST_DEAL_TOT", "Quotes")}</div>
                <div>{sAction.formatNumber(sums["total_discount_amount"])}</div>
            </div>
            <div className={"QTotalContainerRowSumLbl SumsDiscouPriceContainer"+readonlyClass} style={{width:"13.2%"}}>{sAction.translate("LBL_NEW_SUB", "Quotes")}</div>
            <div style={{width: "12.5%"}} className="amountsContainer" key={"sum_total_discount_price"}>
                <div className="SumsDiscouPriceContainer">{sAction.formatNumber(sums["total_discount_price"])}</div>
            </div>
        </div>
        <div className="QTotalContainerRow">
            <div className={"QTotalContainerRowSumLbl"+readonlyClass} >{sAction.translate("LBL_TAX", "Quotes")}</div>
            <div style={{width: "12.5%"}} className="amountsContainer" key={"sum_total_tax"}>
                <div>{sAction.formatNumber(sums["total_tax"])}</div>
            </div>
        </div>
        <div className="QTotalContainerRow">
            <div className="QTotalContainerRowSumLbl" >{sAction.translate("LBL_TOTAL", "Quotes")}</div>
            <div style={{width: "12.5%"}} className="amountsContainer" key={"sum_total"}>
                <div>{sAction.formatNumber(sums["total"])}</div>
            </div>
        </div>

    </div>;
    }
}

export default QTotal