import React from "react";
import PureComponent from "../../pure";

import Select from "../../formElements/Select";
import InputText from "../../formElements/InputText";

import ReportWindowColumn from "./ReportWindowColumn";
import  sAction  from "sAction";
class ReportWindowColumnsContainer extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      searchValue: null
    };
    this.searchInput = React.createRef();
  }
  searchChange() {
    const inputValue = this.searchInput.current.value;
    this.setState({
      searchValue: inputValue.toLowerCase(),
    });
  }


  changeSelected(moduleName) {
    let relatePole = sAction.dataGet("reportWindow/activeRelate").toJS()
    if(relatePole) {
      for (const [key, value] of Object.entries(relatePole)) {
        if (key.toLowerCase() === moduleName.toLowerCase()) {
          value.selected = true
          continue
        }
        value.selected = false

      }
      sAction.dataSet("reportWindow/activeRelate", relatePole)
    }
  }

  render() {
    const data = this.props.data;
    const customClass = this.props.customClass;
    const setWidth = this.props.setWidth;
    const setOrder = this.props.setOrder;
    let active = false;
    var columnsRender = [];
    data.forEach((column, index) => {
      if (column.def != undefined) {
        var vname = sAction.translate(
          column.def.get("vname"),
          column.def.get("fieldModule")
        );

        if(vname) {
          vname = vname.replace(":", "");
          var add = true;
          if (
              this.state.searchValue != null &&
              vname.toLowerCase().indexOf(this.state.searchValue) == -1
          ) {
            add = false;
          }

          if (this.props.modulName == true) {
            active = true
            vname = [
              <div className="reportWindowSelectFields names" key="first" data-way="first">
                {vname}
              </div>,
              <div className="reportWindowSelectFields modules" key="second">
                {sAction.app_strings.moduleList[column.def.get("fieldModule")]}
              </div>,

                <div className="reportWindowSelectFields" key="third">
                  {column.name + " | " + sAction.findTypeTranslation(column.def.get("type"))}
                </div>



            ];
          } else {
            vname = [
                <div className="reportWindowSelectFields first" key="first" data-way="first">
                  {vname}
                </div>,
                <div className="reportWindowSelectFields" key="second">
                  {column.name + " | " + sAction.findTypeTranslation(column.def.get("type"))}
                </div>

            ]
          }
          if (add == true) {
            columnsRender.push(
                <ReportWindowColumn
                    key={index + column.name}
                    index={this.props.containerName + "/" + index}
                    module={data.module}
                    vname={vname}
                    data={column}
                    setWidth={setWidth}
                    setOrder={setOrder}
                />
            );
          }
        }
      }
    });


    //serazeni hidden sloupcu podle abecedy
    if(!active) {
     columnsRender.sort((a, b) =>
         a.props.vname[0].props.children.localeCompare(b.props.vname[0].props.children))
    }

    columnsRender.push(
      <div className="reportWindowColumn" style={{border:"none"}} key="last" data-way="last"></div>
    )

    var modulPanel = null;
    var defaultSelectValue = null;
    if (this.props.withModuleSelect == true) {
      const relate = this.props.activeRelate;
      var options = [];
      relate.forEach((modul,key) => {

        if(modul.get("selected")){
          defaultSelectValue = key
        }

        options.push({
          value: key,
          label: sAction.app_strings.moduleList[modul.get("module")]
        });
      });
      modulPanel = (
        <div className="reportWindowModulListContainer">
          <div className="moduleList">
            <span>{sAction.translate("LBL_REPORT_MODULE")}</span>
            <Select
              defaultValue={defaultSelectValue}
              containerClassName="withBorder"
              onChange={(e) => {sAction.reportWindowColumnsModule(e.target.value); this.changeSelected(e.target.value)}}
              options={options}
            />
          </div>
          <div className="moduleSearch">
            <InputText
              myRef={this.searchInput}
              onKeyUp={() => this.searchChange()}
              className="withBorder"
              placeholder={sAction.translate("LBL_REPORT_SEARCH_FOR")}
            />
          </div>
        </div>
      );
    }
    return (
      <div
        className={customClass}
      >
        {modulPanel}
        <div className={this.props.moveClass}
        data-holderclassname="reportWindowColumns"
        data-way={this.props.containerName}
        >
        {columnsRender}
        </div>
      </div>
    );
  }
}
export default ReportWindowColumnsContainer;
