

export default function sendFileConfirm(params) {
  if (!this.requiredFieldsCheckMsg(params.prefix)) {
    return;
  }

  //popup, kde se zeptame na typ faktury
  const { module } = params;

  params = {...params, emailType: params.buttonId }
  const paramData = {...params, filetype: "pdf", save: true};

  let data, message;
  if (module === "Quotes") {
    data = {
      header: "Tisk nabídky",
      buttons: [
        {
          label: "S",
          callback: () => this.quoteSendEmail({...params, type: "s"}),
        },
        {
          label: "BEZ",
          callback: () => this.quoteSendEmail({...params, type: "bez"}),
        },
      ],
    };

    message = "Chcete nabídku vytisknout S nebo BEZ celkového součtu nabídky?";

    this.popup(message, data);
  } else if (module === "acm_invoices") {
    data = {
      // Tisk faktury/objednávky
      header: this.translate("LBL_SEND_INV", module),
      buttons: [
        { label: this.translate("LBL_CANCEL"), callback: () => this.popupHide() },
        // Tisk faktury/objednávky
        {
          label: this.translate("LBL_SEND_INV", module),
          callback: () => this.getSavedPrintFile({...paramData, type:"all"}),
        },
        // "Tisk souhrnné faktury/objednávky"
        {
          label: this.translate("LBL_SEND_SUMINV", module),
          callback: () => this.getSavedPrintFile({...paramData, type:"summary"}),
        },
      ],
    };

    // Volba "Poslat souhrnou fakturu/objednávku" pošle fakturu bez řádků faktury/objednávky.
    // Volba "Poslat fakturu/objednávku" pošle fakturu včetně řádků faktury/objednávky.
    message =
      this.translate("LBL_TSENDEXT_SUMMARY_INV", module) + "<br>" + this.translate("LBL_SENDTEXT_INV", module);

    this.popup(message, data);
  } else if (module === "acm_orders") {
    const lines = this.dataGet(params.prefix+"/customData/customLines/lines")
    if (!lines || !lines.size) {
      this.confrim("Pozor: K objednávce nejsou přidány žádné řádky, chcete přesto pokračovat?", () => {
        this.popupHide();
        this.ordSendEmail(paramData);
      });
    } else {
      this.ordSendEmail(paramData);
    }
  } else {
    this.getSavedPrintFile(paramData);
  }
}
