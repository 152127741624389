import detailDefault from "../../detailDefault";

export default class detailacm_orders extends detailDefault {
  load(sAction, data) {
    this.changeTabs(sAction, data);

    if (!sAction.dataGet(data.prefix + "/fields/zlv_opm/value")) {
      const zlv_opm = {
        name: "zlv_opm",
        value: sAction.dataGet(data.prefix + "/fields/assigned_user_name/value"),
        way: data.prefix+"/fields/zlv_opm"
      }
      sAction.saveField(zlv_opm, true);
    }
  }

  update(sAction, data) {
    switch (data.field) {
      case "typzl":
        this.changeTabs(sAction, data)
        break;
      case "assigned_user_id":
        const zlv_opm = {
          name: "zlv_opm",
          value: data.value.name,
          way: data.prefix + "/fields/zlv_opm"
        }
        sAction.saveField(zlv_opm, true);
        break;
    }
  }

  changeTabs(sAction, data) {
    let typzl = (data.value) ? data.value : sAction.dataGet(data.prefix + "/fields/typzl/value");

    const tabs = sAction.dataGet(data.prefix + "/tabs");

    if (!tabs) {
      return;
    }

    const tab_vyroba_index = tabs.findIndex(x => x.name === "lbl_detailview_panel_vyroba");
    const tab_sluzba_index = tabs.findIndex(x => x.name === "lbl_detailview_panel_sluzba");

    sAction.dsClear();
    if (typzl === "sluzba") {
      sAction.dsAdd("set", data.prefix + "/tabs/" + tab_vyroba_index + "/hidden", true);
      sAction.dsAdd("set", data.prefix + "/tabs/" + tab_sluzba_index + "/hidden", false);
    } else if (typzl === "vyroba") {
      sAction.dsAdd("set", data.prefix + "/tabs/" + tab_vyroba_index + "/hidden", false);
      sAction.dsAdd("set", data.prefix + "/tabs/" + tab_sluzba_index + "/hidden", true);
    } else {
      sAction.dsAdd("set", data.prefix + "/tabs/" + tab_vyroba_index + "/hidden", true);
      sAction.dsAdd("set", data.prefix + "/tabs/" + tab_sluzba_index + "/hidden", true);
    }
    sAction.dsProcess();
  }

}
