import $ from "jquery";
import detailDefault from "../../detailDefault";

export default class detailOpportunities extends detailDefault {
  load(sAction, data) {
    const sales_stage = sAction.dataGet(data.prefix + "/fields/sales_stage/value");
    if (sales_stage == "Closed Lost" ||
      sales_stage == "Closed canceled client" ||
      sales_stage == "Closed canceled me"
    ) {
      sAction.dsClear();
      sAction.dsAdd("set", data.prefix + "/fields/duvod_uzavreni_c/def/readonly", false);
      sAction.dsAdd("set", data.prefix + "/fields/duvod_uzavreni_c/def/required", true);
      sAction.dsAdd("set", data.prefix + "/fields/pripominky_k_uzavreni_c/def/readonly", false);

      sAction.dsProcess();
    } else {
      sAction.dsAdd("set", data.prefix + "/fields/duvod_uzavreni_c/def/required", false);
      sAction.dsProcess();
    }

    // 4.5.3.2	Akvizice = Aktivní KN – Povinná vazba KN
    // Pokud je atribut akvizice = aktivní s KN, je nutná vazba na Kreativní návrh, příležitost je v počátečním stavu koncept. Pokud není vyplněna vazba na KN, nelze uložit.

    const akvizice = sAction.dataGet(data.prefix + "/fields/akvizice/value");


    if (akvizice === "aktivni s KN") {
      sAction.dataSet(data.prefix + "/fields/acm_kreativni_navrh_opportunities_1_name/def/required", true);
    }

    if (document.getElementById("poslat_k_odsouhlaseni")) {
      sAction.dataSet(data.prefix + "/fields/sales_stage/def/readonly", true);
    }
  }

  update(sAction, data) {
    if (data.field == "account_id") {
      const name = sAction.dataGet(data.prefix + "/fields/name/value");
      if (name == null || name == "") {
        const nameAcc = sAction.dataGet(data.prefix + "/fields/account_name/value");
        sAction.dsClear();
        sAction.dsAdd("set", data.prefix + "/fields/name/value", nameAcc);
        sAction.dsAdd("set", data.prefix + "/changes/fields/name", nameAcc);
        sAction.dsProcess();
      }
    } else if (data.field == "sales_stage") {
      if (data.value == "Closed Lost" ||
        data.value == "Closed canceled client" ||
        data.value == "Closed canceled me"
      ) {
        sAction.dsClear();
        sAction.dsAdd("set", data.prefix + "/fields/duvod_uzavreni_c/def/readonly", false);
        sAction.dsAdd("set", data.prefix + "/fields/duvod_uzavreni_c/def/required", true);
        sAction.dsAdd("set", data.prefix + "/fields/pripominky_k_uzavreni_c/def/readonly", false);


        sAction.dsProcess();
      } else {
        sAction.dsClear();
        if (data.value == "Closed Won") {
          sAction.dsAdd("set", data.prefix + "/fields/pripominky_k_uzavreni_c/def/readonly", false);
        } else {
          sAction.dsAdd("set", data.prefix + "/fields/pripominky_k_uzavreni_c/def/readonly", true);
        }


        sAction.dsAdd("set", data.prefix + "/fields/duvod_uzavreni_c/def/required", false);
        sAction.dsProcess();

      }
    } else if (data.field == "akvizice") {

      let required = data.value === "aktivni s KN";
      sAction.dataSet(data.prefix + "/fields/acm_kreativni_navrh_opportunities_1_name/def/required", required);

    }

  }

}
