export default function quoteActivate(data) {
  const activateQuote = () => {
    const field = {way: data.prefix + "/fields/quote_stage", name: "quote_stage", value: "Active"};
    this.saveField(field, true);
    const save_record = document.querySelectorAll("#save_record");
    save_record[save_record.length - 1].click();

    if (this.dataGet(data.prefix + "/canSave")) {
      const opportunity_id = this.dataGet(data.prefix + "/fields/opportunity_name/def/id_value");
      if (opportunity_id) {
        const data = {
          opportunity_id,
          sales_stage: "Value Proposition",
        };
        this.rest.post("opportunityStatusChange", data, returnData => {

        }, false);
      }
    }
  }

  this.load();
  this.rest.post("canActivateQuote", {recordId: data.id}, (returnData) => {
    this.unLoad();
    if (!returnData.isAttachedFile) {
      this.error("Nelze pokračovat - není přiložen dokument v kategorii 1_podklady od klienta nebo 2_design UNIBON.");
    } else {
      activateQuote();
    }
  });
}