export default function quoteCreateOrder(data) {
  if (this.dataGet("view/fields/date_modified/value") < "2021-02-05") {
    this.alert("Pro vytvoření objednávky prosím přeuložte záznam.");
    return;
  }

  const vyplneno = checkRequiredItems(this, data);

  if (!vyplneno) {
    return;
  }

  const createOrder = () => {
    const params = { action: "quote_create_ord", record: data.id };

    this.load();
    this.rest.post("customAction", params, (resp) => {
      if (resp.errCode == "OK") {
        this.href("#detail/acm_orders/" + resp.record);
      } else {
        this.unLoad();
        this.error(resp.errMsg || "Omlouváme se, nastala neočekávaná chyba");
      }
    });
  }

  // if (data.isAttachedFile) {
  //   createOrder();
  // } else {
    this.load();
    this.rest.post("canCreateOrderFromQuote", {recordId: data.id}, (returnData) => {
      this.unLoad();
      if (!returnData.isAttachedFile) {
        this.error("Nelze pokračovat - není přiložen dokument v kategorii 6_objednávka od klienta.");
      } else {
        createOrder();
      }
    });
  // }

}

function checkRequiredItems(sAction, data) {
    // 4.6.3.12	Kontrola na vyplňenost položky
    // Kontrola na vyplněnost pole položky „činnost“  a „svítí“ musí být pouze v případě přechodu do stavu Objednáno (tlačítko Objednáno).

    let vyplneno = true;
    const groups = sAction.dataGet(data.prefix + "/customData/productData/groups");
    if (!groups) {
        return;
    }
    for (let group of groups) {
        for (let line of group.lines) {
            if (!line.sviti || !line.cinnost) {
                vyplneno = false;
                break;
            }
        }
        if (!vyplneno) {
            break;
        }
    }
    if (!vyplneno) {
        sAction.error("Nelze přejít do stavu Objednáno, jelikož nejsou vyplněny všechny položky Činnost a Svítí.");
    }

    return vyplneno;
}