import React from "react";
import DateFnsUtils from '@date-io/date-fns';
import czLocale from "date-fns/locale/cs";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import sAction from "sAction"

const localeMap = {
    cz: czLocale,
};

export default class AcmDate extends React.Component {
    constructor(props) {
        super()
        this.state = {
            value: this.entryFormat(props.value),
            open:true,
        }
        this.input = React.createRef();
        this.dateFormat = sAction.getDateFormat("datepicker");
    }
    componentDidMount(props){
        if(this.props.autoOpen == true){
            const button = this.input.current.parentElement.querySelector("button")
            if(button){
                button.click();
            }
        }
    }
    entryFormat(date){
        if( ! date){
            return null
        }
        return date
    }
    onChange(date) {
        // Smazani pri kliknuti na "Smazat"
        if (date === null) {
            this.setState({
                value: null,
                open: false,
            });
            if (this.props.onChange !== undefined) {
                this.props.onChange('');
            }
        }

        // Pri nevalidnim datu se napise 'Invalid Date' a vsude by se pak propsalo - je potreba ho stornovat
        if (!date || date === 'Invalid Date') {
            // Pokud se na rest posle null, hodnota se neupravi. Kvuli tomu je potreba poslat prazdny string, aby se datum smazalo
            date = '';
        }

        if (date) {
            const dateObj = new Date(date)
            const month = dateObj.getMonth() < 9 ? "0" + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1
            const day = dateObj.getDate() < 10 ? "0" + dateObj.getDate() : dateObj.getDate()
            const year = dateObj.getFullYear()

            if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
                date = year + "-" + month + "-" + day;
            } else {
                // Pokud se na rest posle null, hodnota se neupravi. Kvuli tomu je potreba poslat prazdny string, aby se datum smazalo
                date = '';
            }
        }

        if (date) {
            this.setState({
                // Prazdny string hodi invalid date, je potreba ulozit jako null
                value: date ? date : null,
                open: false
            });
        }
        if (this.props.onChange !== undefined) {
            this.props.onChange(date);
        }
    }

    render() {
        let value = this.state.value;
        if (this.props.defaultValue) {
            value = this.props.defaultValue;
        }
        // console.log(this.state.value)
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["cz"]}>
                <KeyboardDatePicker
                    className={"AcmDate " + this.props.className || null}
                    autoOk={true}
                    clearable
                    value={value}
                    autoFocus={this.props.autoFocus ?? false}
                    onChange={date => this.onChange(date)}
                    onClose={() => this.props.onClose != undefined ? this.props.onClose() : {}}
                    cancelLabel={sAction.translate("LBL_DATEPICKER_CANCEL")}
                    okLabel={sAction.translate("LBL_DATEPICKER_OK")}
                    clearLabel={sAction.translate("LBL_DATEPICKER_CLEAR")}
                    todayLabel={sAction.translate("LBL_DATEPICKER_TODAY")}
                    showTodayButton={true}
                    format={this.dateFormat}
                    inputRef={this.input}
                    label={this.props.label}
                />
            </MuiPickersUtilsProvider>
        )
    }
}
