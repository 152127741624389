export default function quoteSendEmail(data){
  this.load();
  this.rest.post(
    "getQuoteTemplate",
    data,
    email => {
      this.unLoad();

      if (email.status === "fatal") {
        this.error(email.message);
        return;
      }

      const emailData = {
        parent_type: data.module,
        parent_id: data.id,
        parent_name: data.name,

        to_addrs: email.to_addrs || "",
        cc_addrs: email.cc_addrs || "",

        recordModule: data.module,
        recordId: data.id,
        keepSelf: true,

        description_html: email.text,
        name: email.subject,

        attachments: email.attachments,

        emailType: data.emailType
      };

      this.openEmailPopup(emailData);
    }
  );


}