export default function currencyConvertFields(prefix, oldCur, newCur, convRate = null) {
  const fields = this.dataGet(prefix + "/fields");
  if (!fields) {
    return;
  }


  fields.valueSeq().forEach(data => {
    if (!data.def) return;
    if (data.def.get("type") === "currency") {
      const newValue = this.currencyConvert(data.value, oldCur, newCur);
      this.dsClear();
      this.saveField({
        name: data.name,
        value: newValue,
        way: prefix + "/fields/" + data.name
      }, true);
    }
  });
}