export default function odeslatDoPohody(data){
    if (!this.requiredFieldsCheckMsg(data.prefix)) {
        return;
    }
    this.load();
    this.rest.post("odeslatDoPohody", data, (returnData) => {
        this.unLoad();
        if (returnData.status === "error") {
            return;
        } else if(returnData.status !== "ok") {
            this.error("Nastala neočekávaná chyba");
            return;
        }

        this.unLoad();

        const emailData = {
            parent_type: data.module,
            parent_id: data.id,
            parent_name: data.name,

            to_addrs: returnData.to_addrs || "",
            cc_addrs: returnData.cc_addrs || "",

            recordModule: data.module,
            recordId: data.id,
            keepSelf: true,

            description_html: returnData.text,
            name: returnData.subject,

            attachments: returnData.attachments,

            emailType: data.buttonId
        };

        this.openEmailPopup(emailData);
    });
}