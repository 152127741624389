
import detailDefault from "../../detailDefault";
export default class detailProducts extends detailDefault{
  load(sAction, data) {

  }

  update(sAction,data) {
    let cost_price;

    sAction.dsClear();
    switch (data.field) {
      case "koeficient":
        cost_price = sAction.dataGet(data.prefix + "/fields/cost_price/value") || 0;

        sAction.dsAdd("set",data.prefix + "/fields/list_price/value", cost_price * data.value);
        sAction.dsAdd("set",data.prefix + "/changes/fields/list_price", cost_price * data.value);

        //no break;

      case "list_price":
      case "discount_amount":
      case "discount_select":
      case "cost_price":
        if (!cost_price) {
          cost_price = sAction.dataGet(data.prefix + "/fields/cost_price/value") || 0;
        }
        let list_price = sAction.dataGet(data.prefix + "/fields/list_price/value") || 0;
        let discount = sAction.dataGet(data.prefix + "/fields/discount_amount/value") || 0;
        let discount_select = sAction.dataGet(data.prefix + "/fields/discount_select/value") || 0;

        discount = sAction.parseNumber(discount);
        if (discount_select == "1") {
          discount = (sAction.parseNumber(list_price) / 100) * discount;
        }

        //jednotkova po sleve
        let val = sAction.parseNumber(list_price) - discount;
        sAction.dsAdd("set", data.prefix + "/fields/discount_price/value", val);
        sAction.dsAdd("set", data.prefix + "/changes/fields/discount_price", val);

        //koeficient
        sAction.dsAdd("set", data.prefix + "/fields/koeficient/value", !cost_price || cost_price === "0" ? 0 : val / cost_price);
        sAction.dsAdd("set", data.prefix + "/changes/fields/koeficient", !cost_price || cost_price === "0" ? 0 : val / cost_price);

        sAction.dsProcess();
        break;
    }
  }

}
