export default function uploadFiles(files, way) {
  var formData = {
    files: files
  };
  const list = this.dataGet(way);
  if (list.listViewTree !== undefined) {
    if (list.listViewTree !== null) {
      const activeCategory = list.listViewTree.get("activeCategory");
      const fieldName = list.listViewTree.get("field");
      formData["fieldData"] = {};
      formData["fieldData"][fieldName] = activeCategory;
    }
    // ______________________________________ if is subpanel
    if (list.type == "subpanel") {
      var relData = this.dataGet(way + "/../def/rel");
      var parentId = this.dataGet(way + "/../../../id");

      var relData = {
        module: relData.get("module"),
        relname: relData.get("relName"),
        id: parentId
      };
      formData["relData"] = relData
    }

    // ______________________________________ if is subpanel
    this.popup("fileUpload", { percent: 0, loading: true, onClose: () => {
      if (way !== "view") {
        var relData = this.dataGet(way + "/../def/rel");
        if (relData && relData.get("module") === "Quotes") {
          //ulozeni nabidky pro to, aby se zobrazila nektera tlacitka nahore - Objednavka atd.
          const save_button = document.getElementById("save_record");
          const id = this.dataGet(way+"/listViewTree/activeCategory");
          if (save_button && id) {
            const reload = setInterval(() => {
              if (!this.dataGet("conf/load")) {
                clearInterval(reload);
                setTimeout(() => {
                  this.dsClear();
                  this.dsAdd("set", way + "/listViewTree/activeCategory", id);
                  this.dsAdd("set", way + "/defaultFilter/tree/parentId", [id]);
                  this.dsProcess();
                  this.reloadList(0, 1, way);
                }, 300);
              }
            }, 2000);
          } else {
            this.reloadList(0,1,way);
          }
        } else {
          this.reloadList(0,1,way);
        }
      } else {
        this.reloadList(0,1,way);
      }
    }});
    this.filesXhr({
      action: "uploadFile",
      data: formData,
      success: data => {
          if(data.state == true){
            this.dataSet("conf/popup/data/loading", false);
          }
      },
      progress: data => {
        this.dataSet("conf/popup/data/percent",data.percent);
      }
    });
  }
}
