export default function createKN(data) {
  //TT TODO tady by se jeste asi mely kontrolovat cahnges (pokud nejsou prazdne (fieldy a lines), tak upozornit prez confirm)
  //TODO mozna vyvolat confirm


  const params = {action: "quote_create_kn", record : data.id};

  this.load();
  this.rest.post("customAction", params, resp => {
    if(resp.errCode == "OK"){
      this.href("#detail/acm_kreativni_navrh/"+resp.record);
    }
    else{
      this.unLoad();
      this.alert(resp.errMsg);
    }

  });
}