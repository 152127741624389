import DefaultVarchar from '../../defaultFields/varchar/varchar'

export default class Varchar_name_alias extends DefaultVarchar {
  constructor(){
    super();
    this.state = {
      state: "fine",
        reg: /^[a-zA-Z0-9]+$/,
    };
  }
}
