export default function printFileConfirm(params) {
  //popup, kde se zeptame na typ faktury
  const { module } = params;
  
  if (this.dataGet(params.prefix+'/fields/lang_select/value')){
    params.selectedLang = this.dataGet(params.prefix+'/fields/lang_select/value');
  }
  
  let data, message;
  if (module === "Quotes") {
    data = {
      header: "Tisk nabídky",
      buttons: [
        {
          label: "S",
          callback: () => this.printPrepare({...params, type: "s"}),
        },
        {
          label: "BEZ",
          callback: () => this.printPrepare({...params, type: "bez"}),
        },
      ],
    };

    message = "Chcete nabídku vytisknout S nebo BEZ celkového součtu nabídky?";
    this.popup(message, data);
  } else if (module === "acm_invoices") {
    data = {
      // Tisk faktury/objednávky
      header: this.translate("LBL_PRINT_INV", module),
      buttons: [
        { label: this.translate("LBL_CANCEL"), callback: () => this.popupHide() },
        // Tisk faktury/objednávky
        {
          label: this.translate("LBL_BTN_PRINTINV", module),
          callback: () => this.printPrepare({...params, type: "all"}),
        },
        // "Tisk souhrnné faktury/objednávky"
        {
          label: this.translate("LBL_PRINT_SUMINV", module),
          callback: () => this.printPrepare({...params, type: "summary"}),
        },
      ],
    };

    // Volba "Poslat souhrnou fakturu/objednávku" pošle fakturu bez řádků faktury/objednávky.
    // Volba "Poslat fakturu/objednávku" pošle fakturu včetně řádků faktury/objednávky.
    message = this.translate("LBL_TEXT_SUMMARY_INV", module) + "<br>" + this.translate("LBL_TEXT_INV", module);
    this.popup(message, data);
  } else {

    this.printPrepare(params);
  }
}
